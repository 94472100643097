import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import {
  Title,
  MainTitle,
  Subtitle,
} from '../components/title';
import {
  BlogListing,
  BlogSection,
} from '../templates/display/blog-list-template';
import BlogCard from '../components/blog-card';
import TitleButtonIcon from '../components/title-button';
import { SubscriptionForm } from '../components/subscribe-form';

const Index = ({ data }) => {
  const posts = data.blog?.nodes;
  const portfolio = data.portfolio?.nodes;

  return (
    <Layout>
      <SEO title="All posts" />
      <Hero>
        <MainTitle>
          Hi! My name is Richard.
          <p>
            I’m a
            <span tw="text-red-500"> software developer</span>
          </p>
          located in Canada.
        </MainTitle>
        <Subtitle>
          I am mostly known for my Linux, Security and Embedded experience, but I am have demonstrated experience delivering quality in any technology of software I focus on.
          Earlier in my career I did more web application work, and would be interested in demonstrating my proficiency here again.
        </Subtitle>
      </Hero>

      {/* <div id="portfolio">
         <BlogSection>
          <div tw="flex justify-between items-center pb-8">
            <Title tw="pb-0">
              Projects
            </Title>
            <button type="button" tw="text-primary fill-current">
              <TitleButtonIcon />
            </button>
          </div>
          <BlogListing>
            {portfolio.length > 0 && portfolio.map((project) => {
              const {
                slug, images,
              } = project;
              return (
                <BlogCard
                  key={slug}
                  image={images[0]}
                />
              );
            })}
          </BlogListing>
        </BlogSection>
      </div> */}
{/*
      <BlogSection>
        <Link to="/blog" tw="flex justify-between items-center pb-8">
          <Title tw="pb-0">
            Blog
          </Title>
          <button type="button" tw="text-primary fill-current">
            <TitleButtonIcon />
          </button>
        </Link>
        <BlogListing>
          {posts.length > 0
            && posts.map((post) => {
              const {
                image, title, description, slug, date,
              } = post;
              return (
                <BlogCard
                  key={slug}
                  title={title}
                  date={date}
                  description={description}
                  image={image.childImageSharp.gatsbyImageData}
                  link={`/blog${slug}`}
                />
              );
            })}
        </BlogListing>
      </BlogSection> */}
      {/*
      <SubscriptionForm /> */}
    </Layout>
  );
};

export default Index;

export const mainPageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    portrait: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 650
          quality: 97
          layout: CONSTRAINED
          aspectRatio: 0.87
        )
      }
    }
    blog: allSitePost(
      sort: { fields: [date], order: DESC }
      filter: { posttype: { eq: "blog" } }
      limit: 2
    ) {
      nodes {
        excerpt
        slug
        date(formatString: "DD.MM.YYYY")
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 746
              quality: 97
              layout: CONSTRAINED
              aspectRatio: 1.87
            )
          }
        }
      }
    }
    portfolio: allSitePost(
      sort: { fields: [date], order: DESC }
      filter: { posttype: { eq: "project" } }
    ) {
      nodes {
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        description
        images {
          childImageSharp {
            gatsbyImageData(quality: 97, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
